<template>
  <b-col xl="3" lg="3" md="4" sm="6" class="mb-1">
    <b-card body-class="p-1" class="cursor-pointer mb-1" @click="openUrl">
      <div class="float-right">
        <b-avatar size="42" :variant="`light-${variant}`">
          <feather-icon :icon="icon" size="21" />
        </b-avatar>
      </div>
      <h6
        class="font-weight-bolder mb-25"
        :class="{ 'text-secondary ': !isVisible }"
      >
        {{ displayValue }}
        <feather-icon
          :icon="isVisible ? 'EyeIcon' : 'EyeOffIcon'"
          size="16"
          class="cursor-pointer ml-50"
          @click.stop="$emit('toggle-visibility')"
        />
      </h6>
      <div class="">
        <span class="font-weight-bolder font-medium-1"
          >{{ title }}
          <template v-if="otherText && isVisible">
            <span class="font-small-3"> - {{ otherText }} </span>
          </template>
        </span>
      </div>
    </b-card>
  </b-col>
</template>

<script>
import { BCol, BAvatar, BCard } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BAvatar,
    BCard,
  },
  props: {
    title: String,
    value: [Number, String],
    icon: String,
    isVisible: Boolean,
    variant: {
      type: String,
      default: "primary",
    },
    url: {
      type: String,
      default: "",
    },
    otherText: {
      type: [Number, String],
      default: "",
    },
  },
  computed: {
    displayValue() {
      return this.isVisible ? this.value : "***";
    },
  },
  methods: {
    openUrl() {
      if (this.url) {
        window.open(this.url, "_blank");
      }
    },
  },
};
</script>
