<template>
  <b-row class="match-height" v-if="data">
    <!-- Universal Toggle -->
    <!-- <b-col md="12" class="mb-1">
      <div>
        <b-button
          variant="primary"
          size="sm"
          class="btn-icon"
          @click="toggleAllVisibility"
          v-b-tooltip.hover.left
          :title="showAll ? 'Hide All' : 'Show All'"
        >
          <feather-icon :icon="showAll ? 'EyeIcon' : 'EyeOffIcon'" size="16" />
        </b-button>
      </div>
    </b-col> -->

    <!-- Statistics Cards -->
    <statistics-card
      v-for="(card, index) in cards"
      :key="index"
      :title="card.title"
      :value="card.value"
      :icon="card.icon"
      :url="card.url"
      :other-text="card.otherText"
      :variant="card.variant"
      :is-visible="showAll"
      @toggle-visibility="toggleAllVisibility"
    />
  </b-row>
</template>

<script>
import StatisticsCard from "./StatisticsCard.vue";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    StatisticsCard,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: null,
    },
    otherData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showAll: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      cards: [
        {
          title: "Today's Collection",
          value: this.data.collection.toLocaleString(),
          otherText: "",
          icon: "TrendingUpIcon",
          variant: "success",
          url: "",
        },
        {
          title: "No. of Receipts",
          value: this.data.collectionCount,
          otherText: "",
          icon: "FileTextIcon",
          variant: "info",
          url: "",
        },
        {
          title: "Students",
          value: this.data.students,
          otherText: "",
          icon: "UserIcon",
          variant: "primary",
          url: "",
        },
        {
          title: "Staff",
          value: this.data.staff,
          otherText: "",
          icon: "UserCheckIcon",
          variant: "warning",
          url: "",
        },
        {
          title: "Inquiries",
          value: this.data.inquiries,
          otherText: "",
          icon: "HelpCircleIcon",
          variant: "info",
          url: "",
        },
        {
          title: "Dues",
          value: this.data.dues.toLocaleString(),
          otherText: `${this.data.duesCount} Students`,
          icon: "AlertCircleIcon",
          variant: "danger",
          url: "",
        },
        {
          title: "Families",
          value: this.otherData.familyCount,
          otherText: "",
          icon: "UsersIcon",
          variant: "primary",
          url: "",
        },
        {
          title: "Monthly Estimation",
          value: this.otherData.monthly.toLocaleString(),
          otherText: "",
          icon: "ActivityIcon",
          variant: "success",
          url: "",
        },
      ],
    };
  },
  created() {
    this.setReport();
  },
  methods: {
    toggleAllVisibility() {
      this.showAll = !this.showAll;
    },
    setReport() {
      let date = new Date().toJSON().split("T")[0];

      let url =
        `https://${this.reportDomain}.myskool.app/Fee/Show?type=dcr&dtFrom=` +
        date +
        "&dtTo=" +
        date +
        "&dbb=" +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId +
        "&format=Class wise";

      const collectionCard = this.cards.find(
        (c) => c.title === "Today's Collection"
      );
      if (collectionCard) collectionCard.url = url;

      const receiptsCard = this.cards.find(
        (c) => c.title === "No. of Receipts"
      );
      if (receiptsCard) receiptsCard.url = url;
    },
  },
};
</script>
