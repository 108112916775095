<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Outstanding Dues</b-card-title>
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <chartjs-component-horizontal-bar-chart
        :height="350"
        :data="horizontalBarChart.data"
        :options="horizontalBarChart.options"
      />
      <!-- <vue-apex-charts
        type="bar"
        height="350"
        :options="chartData.chartOptions"
        :series="chartData.series"
      /> -->
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import ChartjsComponentHorizontalBarChart from "@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentHorizontalBarChart.vue";

import { $themeColors } from "@themeConfig";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      horizontalBarChart: {
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "top",
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function (tooltipItem, data) {
                return `Rs.${tooltipItem.xLabel.toLocaleString()}`;
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  zeroLineColor: chartColors.grid_line_color,
                  borderColor: "transparent",
                  color: chartColors.grid_line_color,
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: $themeColors.warning,
              borderColor: "transparent",
              barThickness: 15,
            },
          ],
        },
      },
      chartData: {
        series: [
          // {
          //   name: "",
          //   data: [68000, 25800, 49000],
          // },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.warning,
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "60%",
              endingShape: "rounded",
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
          tooltip: {
            y: {
              formatter(val) {
                return `Rs. ${parseInt(val).toLocaleString()}`;
              },
            },
          },
        },
      },
    };
  },
  created() {
    let cat = this.items.map((el) => el.feeType);
    let am = this.items.map((el) => el.amount);

    // let temp = {
    //   name: "",
    //   data: cat,
    // };
    // this.chartData.series = [temp];
    // this.chartData.chartOptions.xaxis.categories = cat;

    this.horizontalBarChart.data.labels = cat;
    this.horizontalBarChart.data.datasets[0].data = am;
  },
};
</script>
