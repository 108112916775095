<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6" class="mb-50">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ data.students }}</h4>
              <b-card-text class="font-small-3 mb-0"> Students </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-50">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info">
                <feather-icon size="24" icon="UsersIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ data.staff }}</h4>
              <b-card-text class="font-small-3 mb-0"> Staff </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-50">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-warning">
                <feather-icon size="24" icon="AlertCircleIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ data.inquiries }}</h4>
              <b-card-text class="font-small-3 mb-0"> Inquiries </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-50">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-danger">
                <!-- <feather-icon size="24" icon="DollarSignIcon" /> -->
                <span style="font-size: 20px">Rs</span>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.dues.toLocaleString() }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Dues - {{ data.duesCount }} Students
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-50" v-if="otherData.familyCount > 0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-warning">
                <feather-icon size="24" icon="UsersIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ otherData.familyCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">Families</b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-50" v-if="otherData.monthly > 0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <span style="font-size: 20px">Rs</span>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ otherData.monthly.toLocaleString() }}
              </h4>
              <b-card-text class="font-small-3 mb-0">Monthly Fee</b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    otherData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
