<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Yearly Summary</b-card-title>

      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart
        :height="450"
        :data="lineAreaChart.data"
        :options="lineAreaChart.options"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ChartjsComponentLineChart from "./charts-components/ChartjsComponentLineChart.vue";
import chartjsData from "./chartjsData";

import { $themeColors } from "@themeConfig";
// colors
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    ChartjsComponentLineChart,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    flatPickr,
  },
  props: {
    yearlyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20;
            };
            /* eslint-enable */
          },
        },
      ],
      rangePicker: ["2019-05-01", "2019-05-10"],
      lineAreaChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "top",
            align: "start",
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function (tooltipItem, data) {
                return `Rs.${tooltipItem.yLabel.toLocaleString()}`;
              },
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  color: "transparent",
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: "transparent",
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 10000,
                  min: 0,
                  max: this.yearlyData.highestDues,
                  fontColor: chartColors.labelColor,
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Paid",
              data: [
                40, 55, 45, 75, 65, 55, 70, 60, 100, 98, 90, 120, 125, 140, 155,
              ],
              lineTension: 0,
              backgroundColor: "rgb(40, 199, 111,0.5)",
              pointStyle: "circle",
              borderColor: "transparent",
              pointRadius: 0.5,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBackgroundColor: $themeColors.success,
              pointHoverBorderColor: $themeColors.light,
            },
            {
              label: "Unpaid",
              data: [
                70, 85, 75, 150, 100, 140, 110, 105, 160, 150, 125, 190, 200,
                240, 275,
              ],
              lineTension: 0,
              backgroundColor: "rgb(234, 84, 85,0.8)",
              pointStyle: "circle",
              borderColor: "transparent",
              pointRadius: 0.5,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBackgroundColor: $themeColors.danger,
              pointHoverBorderColor: $themeColors.light,
            },
          ],
        },
      },
    };
  },
  created() {
    // console.log(this.yearlyData);
    this.lineAreaChart.data.datasets[0].data = [];
    this.lineAreaChart.data.datasets[1].data = [];
    this.yearlyData.data.forEach((el) => {
      this.lineAreaChart.data.datasets[0].data.push(el.collection);
      this.lineAreaChart.data.datasets[1].data.push(el.dues);
    });
    // console.log(this.lineAreaChart.data);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
