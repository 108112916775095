<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1"> Yearly Summary </b-card-title>
        <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart
        :height="400"
        :data="data"
        :options="options"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import ChartjsComponentLineChart from "./charts-components/ChartjsComponentLineChart.vue";
import chartjsData from "./chartjsData";
import { $themeColors } from "@themeConfig";
// colors
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
  },
  props: {
    yearlyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20;
            };
            /* eslint-enable */
          },
        },
      ],
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            data: [],
            label: "Paid",
            borderColor: $themeColors.success,
            lineTension: 0.5,
            pointStyle: "circle",
            backgroundColor: $themeColors.success,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: "transparent",
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: chartColors.lineChartPrimary,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [],
            label: "Unpaid",
            borderColor: $themeColors.danger,
            lineTension: 0.5,
            pointStyle: "circle",
            backgroundColor: chartColors.lineChartDanger,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: "transparent",
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: chartColors.lineChartDanger,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: "label",
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.white,
          titleFontColor: "#fff",
          bodyFontColor: "#fff",
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 10000,
                min: 0,
                max: this.yearlyData.highestDues,
                fontColor: chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: "top",
          align: "start",
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      },
    };
  },
  created() {
    // console.log(this.yearlyData);
    this.data.datasets[0].data = [];
    this.data.datasets[1].data = [];
    this.yearlyData.data.forEach((el) => {
      this.data.datasets[0].data.push(el.collection);
      this.data.datasets[1].data.push(el.dues);
    });
    // console.log(this.data);
  },
};
</script>
